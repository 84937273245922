window.Client = {
	init: function() {
		// Do something on initialization
//alert("This is an alert message on init for baseline.");
	},

	onRender: function(screenId) {
		// Do something when the flow renders
document.querySelector('[data-variable="variant"]').value = windowConstants.VARIANT_ID;
//alert("This is an alert message on render for baseline.");

	}
}